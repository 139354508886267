import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { renderRoutes } from 'react-router-config';
import { Link } from 'react-router-dom';

import DropDown from 'components/DropDown';
import Icon from 'components/Icon';

import { CabinetHeader } from 'common/blocks/CabinetHeader';
import { CabinetFooter } from 'common/blocks/CabinetFooter';

import { Auth } from 'common/redux/auth';
import { Cabinet } from 'common/redux/cabinet';
import { selectUserData } from 'common/redux/cabinet/selectors';

import { fetch } from 'common/services/fetch.service';

import CONFIG from 'common/config';

import './index.scss';

@fetch({
  user: [selectUserData, () => Cabinet.getGlobalData()],
}, null, {
  loader: 'global',
})
@withTranslation()
@BEM('cabinet-layout')
export class CabinetLayout extends React.PureComponent {
  static displayName = 'CabinetLayout';

  render() {
    const { route, loading, user, bem, t, i18n } = this.props;

    if (loading) {
      return null;
    }

    return (
      <React.Fragment>
        <Helmet defaultTitle="Кабінет - TAS Energy">
          <meta name="description" content="Кабінет - TAS Energy" />
        </Helmet>
        <section className={bem()}>
          <header className={bem('header')}>
            <section className={bem('header-content')}>
              <CabinetHeader
                user={user}
                onExit={() => Auth.logout()}
              />
            </section>
          </header>
          <article className={bem('main')}>
            <div className={bem('sub')}>
              <Link to="/">
                <img className={bem('logo')} src="/assets/images/logo.svg" alt="Logotype" />
              </Link>
              {user.roles.includes('ROLE_MANAGER') && (
                <a href={CONFIG.SELLER_DASHBOARD_URL} className={bem('switch')}>
                  {t('Перейти у свій дашборд менеджера')}
                  <div className={bem('switch-icon')}>
                    <Icon name="change_circle" size={24} />
                  </div>
                </a>
              )}
              <div className={bem('lang')}>
                {/*<DropDown*/}
                {/*  active={i18n.language}*/}
                {/*  nowrap*/}
                {/*  options={[*/}
                {/*    {*/}
                {/*      label: t('Українська'),*/}
                {/*      value: 'uk',*/}
                {/*    }, {*/}
                {/*      label: t('Русский'),*/}
                {/*      value: 'ru',*/}
                {/*    }*/}
                {/*  ]}*/}
                {/*  onChange={({ value }) => i18n.changeLanguage(value)}*/}
                {/*/>*/}
              </div>
            </div>
            <div className={bem('content')}>
              {renderRoutes(route.routes)}
            </div>
            <footer className={bem('footer')}>
              <CabinetFooter />
            </footer>
          </article>
        </section>
      </React.Fragment>
    );
  }
}
