import React from 'react';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';

import { ErrorLayout } from 'common/layouts/Error';

@withTranslation()
export class CatchProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    const { children, t } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <ErrorLayout type="technical" title={t('Технічна помилка')}>
        {t('Виникла технічна помилка, просимо вибачення за тимчасові незручності.')}
      </ErrorLayout>
    );
  }
}
