import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { H2 } from 'components/Title';
import Button from 'components/Button';

import { AuthorizationLayout } from 'common/layouts/Authorization';

import './index.scss';

@withTranslation()
@BEM('registration-finish-page')
export class RegistrationFinishPage extends React.PureComponent {
  static displayName = 'RegistrationFinishPage';

  render() {
    const { bem, t } = this.props;

    return (
      <React.Fragment>
        <Helmet defaultTitle="Реєстрація - TAS Energy">
          <meta name="description" content="Реєстрація - TAS Energy" />
        </Helmet>
        <AuthorizationLayout
          image="/assets/images/registration-color-bg.png"
        >
          <header className={bem('title')}>
            <H2>{t('Дякуємо, ви успішно пройшли реєстрацію')}</H2>
          </header>
          <img className={bem('img')} src="/assets/images/registration-finish.svg" alt="desktop" width={200} />
          <footer className={bem('footer')}>
            <Button to="/login" block>
              {t('Авторизуватися')}
            </Button>
          </footer>
        </AuthorizationLayout>
      </React.Fragment>
    );
  }
}
