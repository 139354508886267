export class HeadersMap extends null {
	static SEPARATOR = /(?:;|,)/g;
	static OPERATOR = /=/;

	static create(headers) {
	  if (Array.isArray(headers)) {
	    headers = headers.reduce((target, [key, value]) => {
	      target[key.toLowerCase()] = String(value);
	      return target;
      }, {});
    }

		return Object.create(
		  this.prototype,
      Object.getOwnPropertyDescriptors(headers)
    );
	}

	get(key) {
		return this[key];
	}

	set(key, value) {
	  this[key] = value;
	  return this;
  }
}
