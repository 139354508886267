import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import { createMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';

import { loaderMiddleware } from 'components/Loader/redux';
import { notificationsMiddleware } from 'components/Notifications/redux';
import { colibriMiddleware } from 'common/plugins/colibri/middleware';

import i18n, { changeLanguageAction } from 'common/services/i18n.service';

import { createRootReducer } from 'common/redux/reducers';

export const configureStore = ({ history }, initialState = {}) => {
  const reduxDevTools = (window || {}).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = reduxDevTools || compose;

  const middleware = applyMiddleware(
    routerMiddleware(history),
    createMiddleware(),
    thunkMiddleware,
    colibriMiddleware,
    loaderMiddleware,
    notificationsMiddleware,
  );

  const reducer = createRootReducer({ history });

  const store = createStore(reducer, initialState, composeEnhancers(middleware));

  i18n.on('languageChanged', () => {
    store.dispatch(changeLanguageAction())
  });

  return store;
};
