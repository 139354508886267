import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@withTranslation()
@BEM('cabinet-footer-block')
export class CabinetFooter extends React.PureComponent {
  static displayName = 'CabinetFooter';

  render() {
    const { t, bem } = this.props;

    return (
      <section className={bem()}>
        <img className={bem('img')} src="/assets/images/call-center.jpg" />
        <div className={bem('title')}>
          {t('Зв\'язатись з кол-центром')}
        </div>
        <a href="tel:+380800755205" className={bem('phone')}>
          0 800-755-205
        </a>
      </section>
    );
  }
}
