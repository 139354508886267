import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Timer from 'components/Timer';

import { sessionStorage } from 'common/services/storage.service';
import { required, length, validate } from 'common/helpers/validators.helper';
import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import './index.scss';

@withTranslation()
@BEM('registration-step-otp-form')
export class RegistrationStepOTPForm extends React.PureComponent {
  static displayName = 'RegistrationStepOTPForm';

  async onSubmit(values) {
    const phone = sessionStorage.get('registration.phone');

    const data = await withLoader('auth', Auth.registrationStepOTP, Auth)({
      body: {
        ...phone,
        code: values.code,
      },
    });

    if (data.next) {
      this.props.history.push(data.next.path);
    }

    return data;
  }

  render() {
    const { t, bem } = this.props;

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('sub')}>
              {t('SMS - повідомлення з кодом було відправлено на Ваш номер')}
            </div>
            <div className={bem('row')}>
              <div className={bem('code')}>
                <Field
                  name="code"
                  type="text"
                  component={InputMask}
                  mask="999999"
                  label={t('Код')}
                  autoFocus
                  validate={validate(
                    required(),
                    length({
                      length: 6,
                      msg: t('Код складається з 6 цифр')
                    })
                  )}
                />
              </div>
              <div className={bem('timer')} title={t('Надіслати повторно')}>
                <Timer
                  size={32}
                  time={60}
                  onClick={() => this.onResend()}
                />
              </div>
            </div>

            <footer className={bem('footer')}>
              <Button type="submit" disabled={submitting} block>
                {t('Відправити')}
              </Button>
            </footer>
          </form>
        )}
      />
    );
  }
}
