import { Handler } from './handler';

const PLUGINS = new WeakMap();

export const PLUGIN_TYPES = {
	BODY_PARSER: Symbol.for('Plugins.bodyParser'),
	BODY_FORMATTER: Symbol.for('Plugins.bodyFormatter'),
	HOOKS: Symbol.for('Plugins.hooks'),
	MIXIN: Symbol.for('Plugins.mixin'),
};

export class PluginsRegistry extends Handler {
	static create(context) {
		if (PLUGINS.has(context)) {
			return PLUGINS.get(context);
		}

		const parent = Object.getPrototypeOf(context);
		const plugins = Object.create(
			this.prototype,
			Object.getOwnPropertyDescriptors(parent.plugins || {})
		);

		parent.plugins && parent.plugins.onChange((type, config) => {
			!plugins.has(type) && Object.defineProperty(plugins, type, { value: [] });
			plugins.get(type).push(config);
		});

		PLUGINS.set(context, plugins);

		return plugins;
	}

	register(type, config) {
		!this.has(type) && Object.defineProperty(this, type, { value: [] });

		this.get(type).push(config);
		this.trigger({ type, config });

		return this;
	}

	has(type) {
		return Boolean(this[type]);
	}

	get(type) {
		return this[type] || [];
	}

	find(type) {
		return this.get(type)[0];
	}
}
