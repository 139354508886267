import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BEM } from '@bikejs/react-bem';

import Loader from 'components/Loader';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import { H4 } from 'components/Title';
import { withLoader } from 'components/Loader/redux';
import { hideModal } from 'components/Modal/redux';

import { Cabinet } from 'common/redux/cabinet';
import { amountFormatter } from 'common/helpers/string.helper';

import './index.scss';

const AVAILABLE_TYPES = [
  {
    type: 4,
    name: 'ПриватБанк',
    icon: '/assets/images/installments-pb.png',
  }, {
    type: 3,
    name: 'Monobank',
    icon: '/assets/images/installments-mono.png',
  }
];

@withTranslation()
@connect(null, { hideModal })
@BEM('installments-block')
export class InstallmentsBlock extends React.PureComponent {
  static displayName = 'InstallmentsBlock';

  async onSubmit({ period, type }) {
    const { modal: { meta, name }, hideModal } = this.props;

    const result = await withLoader('installment', Cabinet.installmentPay.bind(Cabinet))({
      body: {
        bill_id: meta.id,
        payment_system_id: type,
        amount: meta.amount,
        available_parts_count: period
      }
    });

    if (result.redirect_url) {
      window.location.href = result.redirect_url;
    }

    hideModal(name);
  }

  render() {
    const { modal: { meta }, t, bem } = this.props;

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <H4 className={bem('title')}>
            {t('Оплата частинами')}
          </H4>
        </header>
        <article className={bem('main')}>
          {t('Сума до оплати: {{amount}} грн.', {amount: amountFormatter(meta.amount)})}
        </article>
        <footer className={bem('footer')}>
          {AVAILABLE_TYPES.filter(({type}) => meta.installment.find(item => item.type === type).min <= meta.amount).map(item => (
            <Form
              initialValues={{period: '6', type: item.type}}
              onSubmit={(values) => this.onSubmit(values)}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className={bem('form')}>
                  <div className={bem('bank')}>
                    <img className={bem('icon')} src={item.icon} />
                    {item.name}
                  </div>
                  <div className={bem('period')}>
                    <Field
                      name="period"
                      component={InputSelect}
                      options={[
                        {label: t('3 місяці'), value: '3'},
                        {label: t('6 місяців'), value: '6'},
                        {label: t('9 місяців'), value: '9'},
                        {label: t('12 місяців'), value: '12'},
                      ]}
                    />
                  </div>
                  <div className={bem('value')}>
                    {t('{{amount}} ₴ / місяць', {amount: amountFormatter(meta.amount / values.period)})}
                  </div>
                  <div className={bem('button')}>
                    <Button block size="small" type="submit">{t('Обрати')}</Button>
                  </div>
                </form>
              )}
            />
          ))}
        </footer>
        <Loader name="installment" size={32} />
      </section>
    );
  }
}
