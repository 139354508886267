const STORAGE_KEY = 'tas-energy';

const getByPath = (path, target) => (
  path.split('.').reduce((object, item) => object && object[item], target)
);

const setByPath = (path, target, value) => {
  path = path.split('.');
  let current = target;

  while (path.length > 1) {
    const [head, ...tail] = path;

    path = tail;

    if (current[head] === undefined) {
      current[head] = {};
    }

    current = current[head];
  }

  current[path[0]] = value;

  return target;
};

class Storage {
  storage = {};
  target = null;

  constructor(storageTarget) {
    if (!storageTarget) {
      return ;
    }

    this.target = storageTarget;

    let data = null;

    try {
      data = JSON.parse(this.target.getItem(STORAGE_KEY));
    } catch (e) {}

    if (!data) {
      return ;
    }

    this.storage = data;
  }

  get(path, defaultValue) {
    let data = getByPath(path, this.storage);

    if (data && typeof data === 'object') {
      data = { ...defaultValue, ...data };
    }

    return data || defaultValue;
  }

  set(path, value) {
    const result = setByPath(path, this.storage, value);

    try {
      this.target.setItem(STORAGE_KEY, JSON.stringify(result));
    } catch (e) {}
  }

  remove(path) {
    this.set(path, undefined);
  }

  clear() {
    this.target.removeItem(STORAGE_KEY);
  }
}

export const sessionStorage = new Storage(window && window.sessionStorage);
export const localStorage = new Storage(window && window.localStorage)
