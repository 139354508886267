import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import Icon from 'components/Icon';
import Loader from 'components/Loader';

import { Cabinet } from 'common/redux/cabinet';
import { selectBannersList } from 'common/redux/cabinet/selectors';

import { fetch } from 'common/services/fetch.service';

import './index.scss';

@fetch({
  banners: [selectBannersList, () => Cabinet.getBannersList()]
}, null, {
  loader: 'banners',
})
@withTranslation()
@BEM('news-gallery-block')
export class NewsGalleryBlock extends React.PureComponent {
  static displayName = 'NewsGalleryBlock';

  static propTypes = {
    news: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
        link: PropTypes.string,
      })
    )
  };

  static defaultProps = {
    news: [],
  };

  state = {
    active: 0,
  }

  $news = createRef();

  goTo(index) {
    this.setState({ active: index });
  }

  render() {
    const { banners, t, bem } = this.props;
    const { active } = this.state;
    const isNotSingleBanner = banners.length > 1;

    return (
      <section className={bem()}>
        <ul className={bem('news')} ref={this.$news}>
          {banners.map((item, index) => (
            <li
              className={bem('news-item', { active: active === index })}
              onClick={() => window.open(item.link, '_blank')}
              key={item.title}
            >
              <div
                className={bem('news-image')}
                style={{ backgroundImage: `url(${item.image})` }}
              />
              <div className={bem('news-data')}>
                <div className={bem('news-title')}>{item.title}</div>
                <div className={bem('news-description')}>{item.description}</div>
              </div>
            </li>
          ))}
        </ul>
        {isNotSingleBanner && (
          <ul className={bem('markers')}>
            {banners.map((item, index) => (
              <li
                className={bem('markers-item', { active: index === active })}
                onClick={() => this.goTo(index)}
                key={item.title}
              />
            ))}
          </ul>
        )}
        {isNotSingleBanner && (
          <React.Fragment>
            <div
              className={bem('control', { prev: true, disabled: active === 0 })}
              onClick={() => this.goTo(active - 1)}
            >
              <Icon name="chevron_left" />
            </div>
            <div
              className={bem('control', { next: true, disabled: active === banners.length - 1 })}
              onClick={() => this.goTo(active + 1)}
            >
              <Icon name="chevron_right" />
            </div>
          </React.Fragment>
        )}

        <Loader name="banners" color="black" />
      </section>
    );
  }
}
