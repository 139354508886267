import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { withLoader } from 'components/Loader/redux';

const DEFAULT_OPTIONS = {
  loader: null,
};

export const fetch = (fetchActions, dispatchToProps, options = DEFAULT_OPTIONS) => (ComposedComponent) => {
  const entries = Object.entries(fetchActions);

  ComposedComponent = connect((state, props) => (
    entries.reduce((target, [key, [selector]]) => ({
      ...target,
      [key]: selector(state, props),
    }), {})
  ), dispatchToProps)(ComposedComponent);

  return (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      async function fetchData() {
        await withLoader(options.loader, () => (
          Promise.all(entries.map(([, [, fetch]]) => fetch(props).catch(() => undefined)))
        ))();

        setLoading(false);
      }

      fetchData();
    }, []);

    return React.createElement(ComposedComponent, {
      ...props,
      loading,
    });
  };
};
