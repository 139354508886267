import { createBrowserHistory, createMemoryHistory, createHashHistory } from 'history';

import Query from 'common/helpers/query.helper';

export const createHistory = (mode, ...options) => {
  const history = createNewHistory(mode, ...options);

  const { push, replace } = history;

  history.push = (path, ...args) => {
    push(parsePath(path, history.location), ...args);
  };

  history.replace = (path, ...args) => {
    replace(parsePath(path, history.location), ...args);
  };

  return history;
};

function parsePath(path, currentLocation) {
  if (typeof path === 'string') {
    return path;
  }

  let { query = {}, mergeQuery, ...location } = path;

  if (mergeQuery) {
    query = { ...currentLocation.query, ...query };
  }

  location.search = Query.serialize(query);
  location.query = query;

  return location
}

function createNewHistory(mode, ...options) {
  switch (mode) {
    case 'memory':
      return createMemoryHistory(...options);
    case 'browser':
      return createBrowserHistory(...options);
    case 'hash':
    default:
      return createHashHistory(...options);
  }
}
