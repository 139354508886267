import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { H4 } from 'components/Title';

import Tariff from 'common/components/Tariff';

import './index.scss';

@withTranslation()
@BEM('tariffs-list-component')
export default class TariffsList extends React.PureComponent {
  static displayName = 'TariffsListComponent';

  static propTypes = {
    tariffs: PropTypes.arrayOf(PropTypes.any),
    onSelect: PropTypes.func,
  }

  static defaultProps = {
    onSelect: () => {},
  };

  render() {
    const { tariffs, onSelect, t, bem } = this.props;

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <H4>{t('Тарифні плани')}</H4>
        </header>
        <ul className={bem('list')}>
          {tariffs.map(item => (
            <li key={item.id} className={bem('item')}>
              <Tariff {...item} onClick={() => onSelect(item)} />
            </li>
          ))}
        </ul>
      </section>
    );
  }
}
