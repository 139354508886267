import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import Amount from 'components/Amount';
import Icon from 'components/Icon';

import './index.scss';

@withTranslation()
@BEM('balance-banner-component')
export default class BalanceBanner extends React.PureComponent {
  static displayName = 'BalanceBannerComponent';

  static propTypes = {
    amount: PropTypes.number,
    bonuses: PropTypes.number,
    onPay: PropTypes.func,
    onUseBonuses: PropTypes.func,
  }

  static defaultProps = {
    amount: 0,
    bonuses: 0,
    onPay: () => {},
    onUseBonuses: () => {},
  };

  render() {
    const { amount, bonuses, onPay, onUseBonuses, t, bem } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('title')}>
          <div className={bem('icon')}>
            <Icon name="payments" />
          </div>
          {t('Баланс рахунку')}
        </div>
        <div className={bem('box', { payment: true })}>
          <div className={bem('description')}>
            {t('Загальна сума ')} <br />
            {amount > 0 ? t('передсплати') : t('заборгованості')}
          </div>
          <div className={bem('content')}>
            <div className={bem('amount')}>
              <Amount>{amount}</Amount>
            </div>
            <button disabled className={bem('button')} onClick={onPay}>
              {t('Сплатити')}
            </button>
          </div>
        </div>
        <div className={bem('border')} />
        <div className={bem('box')}>
          <div className={bem('description')}>
            {t('Ваші бонуси')}
          </div>
          <div className={bem('content')}>
            <div className={bem('amount')}>
              {bonuses}
            </div>
            <button disabled className={bem('button')} onClick={onUseBonuses}>
              {t('Використати бонуси')}
            </button>
          </div>
        </div>
      </section>
    );
  }
}
