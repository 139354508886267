import React from 'react';
import { withTranslation } from 'react-i18next';

import { ErrorLayout } from 'common/layouts/Error';

import './index.scss';

@withTranslation()
export class NotFoundPage extends React.PureComponent {
  static displayName = 'NotFoundPage';

  render() {
    const { t } = this.props;

    return (
      <ErrorLayout
        code={404}
        title={t('Не знайдено')}
      >
        {t('На жаль, даний запит не знайдено. Поверніться на головну сторінку або скористайтесь кроком «назад»')}
      </ErrorLayout>
    );
  }
}
