import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import Icon from 'components/Icon';

import './index.scss';

@withTranslation()
@BEM('invoice-controls-component')
export default class InvoiceControls extends React.PureComponent {
  static displayName = 'InvoiceControlsComponent';

  static propTypes = {
    pay: PropTypes.bool,
    onDownload: PropTypes.func,
    onPay: PropTypes.func,
  }

  static defaultProps = {
    pay: true,
    onDownload: () => {},
    onPay: () => { },
  }

  render() {
    const { pay, onDownload, onPay, t, bem } = this.props;

    return (
      <section className={bem()}>
        <button className={bem('download')} type="button" onClick={onDownload}>
          <Icon name="save_alt" />
        </button>
        {pay && (
          <button className={bem('pay')} type="button" onClick={onPay}>
            {t('Сплатити')}
          </button>
        )}
      </section>
    );
  }
}
