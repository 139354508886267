import { API, PLUGIN_TYPES } from '../index';

API.plugins.register(PLUGIN_TYPES.BODY_FORMATTER, {
	match({ request }) {
		return request.type === 'JSON';
	},

	use({ request }) {
		return JSON.stringify(request.body);
	}
});
