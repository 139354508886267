import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputRadio from 'components/InputRadio';
import Button from 'components/Button';

import { sessionStorage } from 'common/services/storage.service';

import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import './index.scss';

@withTranslation()
@BEM('registration-step-phones-form')
export class RegistrationStepPhonesForm extends React.PureComponent {
  static displayName = 'RegistrationStepPhonesForm';

  async onSubmit(values) {
    const data = await withLoader('auth', Auth.registrationStepPhones, Auth)({
      body: values,
    });

    if (data.next) {
      this.props.history.push(data.next.path);
    }
  }

  render() {
    const { t, bem } = this.props;
    const phones = Object.values(sessionStorage.get('registration.first-response', []));

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        initialValues={{
          sendBy: phones.length !== 0 ? phones[0].name : undefined,
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('sub')}>
              {t('Шановний клієнте , оберіть, будь ласка, номер телефону , на який бажаєте отримати смс з кодом')}
            </div>

            {phones.map(item => (
              <div key={item.name} className={bem('row')}>
                <Field
                  name="sendBy"
                  type="radio"
                  component={InputRadio}
                  label={(
                    <div className={bem('label')}>
                      {item.value}
                    </div>
                  )}
                  value={item.name}
                />
              </div>
            ))}
            <footer className={bem('footer')}>
              <Button type="submit" disabled={submitting} block>
                {t('Відправити')}
              </Button>
            </footer>
          </form>
        )}
      />
    );
  }
}
