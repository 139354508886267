import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useBEM } from '@bikejs/react-bem';

import Button from 'components/Button';

import './index.scss';

const IMG_BY_TYPE = {
  code: '/assets/images/not-found-bg.png',
  technical: '/assets/images/technical-error-bg.png'
};

export const ErrorLayout = ({ children, code, title, type = 'code' }) => {
  const bem = useBEM('error-layout');
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <section className={bem({ type })}>
      <header className={bem('header')}>
        <Link to="/">
          <img className={bem('logo')} src="/assets/images/logo.svg" />
        </Link>
      </header>
      <article className={bem('main')}>
        <div className={bem('center')}>
          <div className={bem('content')}>
            <div className={bem('code')}>
              {code}
            </div>
            <div className={bem('title')}>
              {title}
            </div>
            <div className={bem('text')}>
              {children}
            </div>
            <ul className={bem('controls')}>
              <li className={bem('control')}>
                <Button block to="/">
                  {t('На головну')}
                </Button>
              </li>
              <li className={bem('control')}>
                <Button block theme="border" onClick={() => history.goBack()}>
                  {t('Назад')}
                </Button>
              </li>
            </ul>
          </div>
          <div className={bem('picture', { type })}>
            <img src={IMG_BY_TYPE[type]} className={bem('img')}/>
          </div>
        </div>
      </article>
    </section>
  );
};
