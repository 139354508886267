export const stringToHslColor = (string = '', saturation, lightness) => {
  const hash = Array.from(string).reduce((target, letter) => {
    target = letter.charCodeAt(0) + ((target << 5) - target);
    return target;
  }, 0);

  const hue = hash % 360;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const compressName = name => name
  .split(' ')
  .map(([firstLetter]) => firstLetter)
  .slice(0, 2)
  .join('');

export const firstNamesFormatter = name => {
  const [lastName, firstName, secondName] = name.split(' ');
  return `${firstName} ${secondName}`;
}

export const formatPhoneNumber = (value) => (
  value.replace(/^(\+[\d]{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
);

export const numberFormatter = num => (parseFloat(num))
  .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 0 })
  .toString();

export const amountFormatter = num => (parseFloat(num))
  .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2, currency: 'UAH' })
  .toString().split(',').join('.');

export const sum = value => value.reduce((target, item = 0) => target + Number(item), 0);

export const phoneMask = phone => phone.replace(/(\+\d{3}).*(\d{3})/, '$1******$2');
export const emailMask = email => email.replace(/(\w{3}).*(@.*)/, '$1******$2');
