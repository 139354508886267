import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AuthorizationLayout } from 'common/layouts/Authorization';
import { LoginForm } from 'common/forms/Login';

import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import './index.scss';

export class LoginPage extends React.PureComponent {
  async onSubmit(body) {
    const data = await withLoader('auth', Auth.login, Auth)({ body });

    if (data.next) {
      this.props.history.push(data.next.path);
    }

    return data;
  }

  render() {
    return (
      <React.Fragment>
        <Helmet defaultTitle="Авторизація - TAS Energy">
          <meta name="description" content="Авторизація - TAS Energy" />
        </Helmet>
        <AuthorizationLayout
          image="/assets/images/login-color-bg.png"
        >
          <LoginForm
            onSubmit={values => this.onSubmit(values)}
          />
        </AuthorizationLayout>
      </React.Fragment>
    );
  }
}
