const VALIDATION_MESSAGES = {
  REQUIRED: 'Обов\'язкове поле',
  EMAIL: 'Невірна адреса електронної пошти',
  PHONE: 'Невірний номер телефону',
  CLIENT_CODE: 'Невірний ЄДРПОУ/ІПН',
  CLIENT_NAME: 'Невірний формат ПІБ',
  PASSWORD_MATCH: 'Паролі не співпадають',
  PASSWORD_SHORT: 'Пароль повинен мати не менше 8 символів',
  PASSWORD_NO_HAS_NUMBER: 'Відсутні цифри',
  PASSWORD_NO_HAS_LITERALS: 'Відсутні малі латинські літери',
  PASSWORD_NO_HAS_UPPER: 'Відсутні заголовні латинські літери',
  EIC_CODE: 'Код EIC повинен починатися з 56X',
  TKO_CODE: 'Код ТКО повинен починатися з 56Z',
  CLIENT_CODE_OR_PASSPORT: 'Невірний формат ІПН або номера паспорта',
};

const ONLY_NUMBERS = /\d+/;
const PASSPORT_NUMBER_REG_EXP = /^[\d]{6}$/;
const FIO_REG_EXP = /^([А-яІіЇїЄєЁё'`\-]{2,})$/;
const REG_EMAIL = /^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const required = () => (value) => {
  if (!value) {
    return VALIDATION_MESSAGES.REQUIRED;
  }
};

export const email = () => (value) => {
  if (!REG_EMAIL.test(value)) {
    return VALIDATION_MESSAGES.EMAIL;
  }
};

export const clientCode = () => (value) => {
  value = value.trim();

  if (value.length !== 8 && value.length !== 10) {
    return VALIDATION_MESSAGES.CLIENT_CODE;
  }
};

export const clientCodePhysic = () => (value = '') => {
  if (value.length !== 10 || !ONLY_NUMBERS.test(value)) {
    return false;
  }

  return true;
}

export const passport = () => (value = '') => {
  if (value.length !== 6 || !PASSPORT_NUMBER_REG_EXP.test(value)) {
    return false;
  }

  return true;
};

export const clientCodeOrPassport = () => (value = '') => {
  value = value.trim();

  if (!clientCodePhysic()(value) && !passport()(value)) {
    return VALIDATION_MESSAGES.CLIENT_CODE_OR_PASSPORT;
  }
}

export const clientName = () => (value) => {
  if (!value || value.length < 5) {
    return VALIDATION_MESSAGES.CLIENT_NAME;
  }

  const fioArray = value.split(/(\s+)/).filter(item => item.trim().length);

  if (fioArray.length < 3) {
    return VALIDATION_MESSAGES.CLIENT_NAME;
  }

  if (!fioArray.every(item => FIO_REG_EXP.test(item))) {
    return VALIDATION_MESSAGES.CLIENT_NAME;
  }
};

export const password = () => (value) => {
  if (value && value.length < 8) {
    return VALIDATION_MESSAGES.PASSWORD_SHORT;
  }

  if (!(/\d+/).test(value)) {
    return VALIDATION_MESSAGES.PASSWORD_NO_HAS_NUMBER;
  }

  if (!(/[a-z]+/).test(value)) {
    return VALIDATION_MESSAGES.PASSWORD_NO_HAS_LITERALS;
  }

  if (!(/[A-Z]+/).test(value)) {
    return VALIDATION_MESSAGES.PASSWORD_NO_HAS_UPPER;
  }
};

export const mobilePhone = () => (value) => {
  value = value.replace(/[^\d]/g, '');

  if (value.length !== 12) {
    return VALIDATION_MESSAGES.PHONE;
  }
};

export const passwordMatch = ({ password, repeat }) => (values) => {
  if (!values[password] || !values[repeat]) {
    return;
  }

  if (values[password] !== values[repeat]) {
    return {
      [repeat]: VALIDATION_MESSAGES.PASSWORD_MATCH,
    };
  }
};

export const length = ({ length = 1, msg = '' }) => (value) => {
  value = value.trim();

  if (value.length < length) {
    return msg;
  }
};

export const EICCode = () => (value = '') => {
  if (!value.startsWith('56X')) {
    return VALIDATION_MESSAGES.EIC_CODE;
  }
};

export const TKOCode = () => (value = '') => {
  if (!value.startsWith('56Z')) {
    return VALIDATION_MESSAGES.TKO_CODE;
  }
};

export const validate = (...validators) => (value) => {
  return validators.reduce((error, validator) => error || validator(value), undefined);
};
