import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputText from 'components/InputText';
import InputMask from 'components/InputMask';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';

import { validate, required, clientName, mobilePhone } from 'common/helpers/validators.helper';

import './index.scss';

@withTranslation()
@BEM('join-form')
export class JoinForm extends React.PureComponent {
  static displayName = 'JoinForm';

  render() {
    const { onSubmit, t, bem } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('field')}>
              <Field
                name="fio"
                label={t('ПІБ')}
                component={InputText}
                validate={validate(
                  required(),
                  clientName(),
                )}
              />
            </div>
            <div className={bem('field')}>
              <Field
                name="phone"
                label={t('Мобільний телефон')}
                component={InputMask}
                mask="+38 099 999 99 99"
                maskPlaceholder={null}
                autoComplete="off"
                validate={validate(
                  required(),
                  mobilePhone(),
                )}
              />
            </div>
            <footer className={bem('footer')}>
              <Button type="submit" block>{t('Готово')}</Button>
            </footer>
          </form>
        )}
      />
    );
  }
}
