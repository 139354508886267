import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';

import SwitchedBlock from 'components/SwitchedBlock';
import Modal  from 'components/Modal';

import BalanceBanner from 'common/components/BalanceBanner';

import { TariffsBlock } from 'common/blocks/Tariffs';
import { InvoicesBlock } from 'common/blocks/Invoices';
import { PriceChartBlock } from 'common/blocks/PriceChart';
import { NewsGalleryBlock } from 'common/blocks/NewsGallery';
import { BalanceBlock } from 'common/blocks/Balance';
import { InstallmentsBlock } from 'common/blocks/Installments';

import { localStorage } from 'common/services/storage.service';
import { firstNamesFormatter } from 'common/helpers/string.helper';

import { selectUserData, selectTariffsList } from 'common/redux/cabinet/selectors';

import './index.scss';

@connect(state => ({
  user: selectUserData(state),
  tariffs: selectTariffsList(state),
}))
@withTranslation()
@BEM('cabinet-main-page')
export class CabinetMainPage extends React.PureComponent {
  static displayName = 'CabinetMainPage';

  constructor(...args) {
    super(...args);

    this.switches = localStorage.get('switches', {
      invoices: true,
      chart: true,
    });
  }

  render() {
    const { user, tariffs, t, bem } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('row', { zero: true })}>
          <TariffsBlock
            user={firstNamesFormatter(user.name)}
            tariffs={tariffs}
            objects={user.objects}
          />
        </div>
        <div className={bem('row')}>
          <BalanceBlock />
        </div>
        {/*<div className={bem('row')}>*/}
        {/*  <BalanceBanner amount={user.balance} />*/}
        {/*</div>*/}
        <div className={bem('row')}>
          <NewsGalleryBlock />
        </div>
        {/*<div className={bem('row')}>*/}
        {/*  <SwitchedBlock*/}
        {/*    title={t('Архів рахунків')}*/}
        {/*    open={this.switches.invoices}*/}
        {/*    onChange={status => localStorage.set('switches.invoices', status)}*/}
        {/*    reverse*/}
        {/*  >*/}
        {/*    <InvoicesBlock />*/}
        {/*  </SwitchedBlock>*/}
        {/*</div>*/}
        <div className={bem('row')}>
          <SwitchedBlock
            title={t('Динаміка зміни ціни на природний газ')}
            open={this.switches.chart}
            onChange={status => localStorage.set('switches.chart', status)}
            reverse
          >
            <PriceChartBlock />
          </SwitchedBlock>
        </div>
        <Modal name="installments" autoWidth>
          <InstallmentsBlock />
        </Modal>
      </section>
    );
  }
}
