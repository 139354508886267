import { Location } from './location';
import { HeadersMap } from './headers';
import { PLUGIN_TYPES } from './plugins';

const SERVICE_META = Symbol('Service.meta');

const DEFAULT_ENDPOINT_CONFIG = {
	path: '/',
	method: 'GET',
	type: 'JSON',
  headers: {},
  handleCatch: true,
};

export const Service = config => target => {
	Object.defineProperty(target, SERVICE_META, { value: { config } });
	return target;
};

export const Endpoint = config => (target, name, descriptors) => {
	config = Object.assign({}, DEFAULT_ENDPOINT_CONFIG, config);
	const next = descriptors.value.bind(target);

	descriptors.value = async function({ params = { }, body, query = { } } = { }) {
    const serviceMeta = target.constructor[SERVICE_META];
		const location = new Location(
      serviceMeta.config.url,
			config.path,
			{
				params: params,
				query: query
			}
		);

		let request = {
			location,
			body,
			config,
      headers: HeadersMap.create(Object.entries({
        ...serviceMeta.config.headers,
        ...config.headers,
      })),
			method: config.method,
			type: config.type,
      handleCatch: config.handleCatch,
		};

    request = await this.applyHook('beforeRequest', request);

    const context = target;
    const mixins = target.constructor.plugins.get(PLUGIN_TYPES.MIXIN).reduce((target, plugin) => {
      return {
        ...target,
        ...plugin.use({ context }),
      }
    }, {});

		const response = await this.fetch(request, mixins).then(response => (
		  this.applyHook('afterResolve', response, request, mixins)
    )).catch(({ request, response, error }) => (
      this.applyHook('afterReject', response, request, error, mixins)
    ));

		const result = await next(response, request, mixins);

		return result || response;
	};
};
