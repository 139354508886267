import { API, PLUGIN_TYPES } from '../index';

API.plugins.register(PLUGIN_TYPES.BODY_PARSER, {
  match({ response, request }) {
    return request.type === 'JSON' || response.headers.get('content-type').includes('application/json');
  },

  use({ response }) {
    return response.json();
  }
});

