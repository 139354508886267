import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createAction, handleActions } from 'redux-actions';

import UK from 'common/locales/uk.po';
import RU from 'common/locales/ru.po';

i18n.use(initReactI18next);

i18n.init({
  resources: {
    uk: { translation: UK },
    ru: { translation: RU },
  },
  nsSeparator: ':->',
  keySeparator: false,
  fallbackLng: 'uk',
  lng: 'uk',
  whitelist: ['uk', 'ru'],
  initImmediate: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'sub'],
  },
});

export const changeLanguageAction = createAction('i18n/CHANGE_LANGUAGE');

export const i18nReducer = handleActions({
  [changeLanguageAction]: (state, payload) => ({
    ...state,
    lang: i18n.language,
  }),
}, {
  lang: i18n.language,
});

export default i18n;
