import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { Link } from 'react-router-dom';

import { JoinForm } from 'common/forms/Join';

import { withLoader } from 'components/Loader/redux';

import './index.scss';

@withTranslation()
@BEM('join-page')
export class JoinPage extends React.PureComponent {
  static displayName = 'JoinPage';

  state = {
    animate: true,
  }

  async onSubmit(values) {
    await withLoader('global', () => new Promise(resolve => setTimeout(resolve, 1000)))();
  }

  onAnimationEnd() {
    this.setState({ animate: false });
    setTimeout(() => {
      this.setState({ animate: true });
    }, 500);
  }

  render() {
    const { bem, t } = this.props;
    const { animate } = this.state;

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <Link to="/">
            <img className={bem('logo')} src="/assets/images/logo.svg" />
          </Link>
        </header>
        <article className={bem('main')}>
          <div className={bem('center')}>
            <div className={bem('content')}>
              <div className={bem('title')}>
                {t('Вас вітає')} <br />
                {t('TAS Energy!')}
              </div>
              <div className={bem('text')}>
                {t('Дізнатись більше про зміну постачальника газу Ви зможете подавши заявку')}
              </div>
              <div className={bem('form')}>
                <JoinForm
                  onSubmit={values => this.onSubmit(values)}
                />
              </div>
            </div>
            <div onAnimationEnd={() => this.onAnimationEnd()} className={bem('picture', { animate })}>
              <img src="/assets/images/join-page-bg.svg" className={bem('img')}/>
            </div>
          </div>
        </article>
      </section>
    );
  }
}
