import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import Table from '@front-end-tasenergy/components/Table';
import Icon from '@front-end-tasenergy/components/Icon';
import Loader from '@front-end-tasenergy/components/Loader';
import { showModal } from '@front-end-tasenergy/components/Modal/redux';

import { Cabinet } from 'common/redux/cabinet';
import { selectBalance } from 'common/redux/cabinet/selectors';

import { fetch } from 'common/services/fetch.service';

import './index.scss';

@fetch({
  balance: [selectBalance, () => Cabinet.getBalance()]
}, { showModal }, {
  loader: 'balance',
})
@withTranslation()
@BEM('balance-block')
export class BalanceBlock extends React.PureComponent {
  static displayName = 'BalanceBlock';

  render() {
    const { balance, t, bem } = this.props;

    return (
      <section className={bem()}>
        <article className={bem('main')}>
          <Table
            theme="gradient"
            placeholder={(
              <div className={bem('placeholder')}>
                <Loader name="balance" color="white" size={24} />
              </div>
            )}
            columns={[
              {
                id: 'object',
                label: t('Об\'єкт'),
              },
              {
                id: 'period',
                label: t('Період'),
              },
              {
                id: 'volume',
                label: t('Спожито, куб.м'),
                align: 'right',
              },
              {
                id: 'tariff',
                label: t('Тариф, грн/куб.м'),
                align: 'right',
              },
              {
                id: 'balance',
                label: t('Баланс О/Р на початок періоду'),
                align: 'right',
              },
              {
                id: 'periodPay',
                label: t('Нараховано за період'),
                align: 'right',
              },
              {
                id: 'link',
                label: t('Завантажити рахунок'),
                align: 'right',
              },
              {
                id: 'amount',
                label: t('Сума до сплати (з ПДВ)'),
                align: 'right',
                width: 200,
              }
            ]}
            hover
            innerOnClick
            innerRender={({ row: { bills = [] } }) => (
              <div className={bem('detail')}>
                <Table
                  theme="plain"
                  size="small"
                  columns={[
                    {
                      id: 'period',
                      label: t('Період споживання'),
                    },
                    {
                      id: 'balanceStart',
                      label: t('Баланс О/Р на початок періоду'),
                      align: 'right',
                    },
                    {
                      id: 'used',
                      label: t('Споживання, куб.м'),
                      align: 'right',
                    },
                    {
                      id: 'tariff',
                      label: t('Тариф, грн/куб.м'),
                      align: 'right',
                    },
                    {
                      id: 'pay',
                      label: t('Нараховано, за період, грн'),
                      align: 'right',
                    },
                    {
                      id: 'amount',
                      label: t('Сума до сплати на кінець періоду, грн'),
                      align: 'right',
                    },
                    {
                      id: 'payed',
                      label: t('Сплачено в пероді, грн'),
                      align: 'right',
                    },
                    {
                      id: 'date',
                      label: t('Дата оплати'),
                      align: 'right',
                    },
                    {
                      id: 'balanceEnd',
                      label: t('Баланс О/Р на кінець періоду'),
                      align: 'right',
                    },
                    {
                      id: 'link',
                      label: t('Завантажити рахунок'),
                      align: 'right',
                    }
                  ]}
                  rows={bills.map(bill => ({
                    period: bill.month,
                    balanceStart: (
                      <span className={bem('balance', {color: bill.balance_begin >= 0 ? 'green' : 'red'})}>
                        {bill.balance_begin}
                      </span>
                    ),
                    used: bill.volume,
                    tariff: bill.tariff,
                    payed: bill.sum_pay.map(pay => (
                      <div className={bem('balance', {color: pay >= 0 ? 'green' : 'red'})}>
                        {pay.toFixed(2)}
                      </div>
                    )),
                    pay: bill.amount,
                    amount: bill.amount_pay.map(pay => (
                      <div className={bem('balance', {color: pay >= 0 ? 'green' : 'red'})}>
                        {pay.toFixed(2)}
                      </div>
                    )),
                    date: bill.date_pay.map(date => <div>{date}</div>),
                    balanceEnd: (
                      <span className={bem('balance', {color: bill.balance_end >= 0 ? 'green' : 'red'})}>
                        {bill.balance_end}
                      </span>
                    ),
                    link: (
                      <a className={bem('link')} href={bill.pdf_url} target="_blank">
                        <Icon name="download" />
                      </a>
                    ),
                  }))}
                />
              </div>
            )}
            rows={balance.map(item => ({
              bills: item.bills,
              object: (
                <div className={bem('object')}>
                  <div>{item.account_number}</div>
                  <div>{item.address}</div>
                </div>
              ),
              period: item.current_bill.month,
              volume: item.current_bill.volume,
              tariff: item.current_bill.tariff,
              balance: (
                <span className={bem('balance', {color: item.current_bill.balance_begin >= 0 ? 'green' : 'red'})}>
                  {item.current_bill.balance_begin}
                </span>
              ),
              periodPay: item.current_bill.balance_end,
              link: (
                <a
                  className={bem('link')}
                  href={item.current_bill.pdf_url}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon name="download" />
                </a>
              ),
              amount: (
                <div className={bem('amount')}>
                  <div className={bem('amount-value')}>
                    {item.current_bill.amount}
                  </div>
                  {item.current_bill.amount === 0 && (
                    <div className={bem('amount-bill')}>
                      <a
                        href={item.current_bill.liqpay_url}
                        target="_blank"
                        className={bem('amount-button')}
                        onClick={(e) => e.stopPropagation()}
                      >
                        Сплатити
                      </a>
                      {(item.installment || []).some(({min}) => item.current_bill.amount >= min) && (
                        <button
                          type="button"
                          className={bem('installment')}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.showModal('installments', {
                              id: item.current_bill.bill_id,
                              amount: item.current_bill.amount,
                              installment: item.installment || [],
                            })
                          }}
                        >
                          {t('Оплата частинами')}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )
            }))}
          />
        </article>
      </section>
    );
  }
}
