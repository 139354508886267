export default ({ env, clientGlobal }) => TargetConfig => class Config extends TargetConfig {
  constructor(...args) {
    super(...args);

    if (window && window[clientGlobal]) {
      try {
        const config = JSON.parse(decodeURIComponent(window[clientGlobal]));
        return this.apply(config);
      } catch (e) {
      }
    }

    if (env && Object.keys(env).length) {
      this.apply(env);
    }
  }

  apply(config) {
    if (!config || !Object.keys(config).length) {
      return this;
    }

    Object.keys(this).forEach(key => {
      if (config[key] !== undefined) {
        this[key] = config[key];
      }
    });

    return this;
  }
}
