import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { Endpoint, HttpMethod } from 'common/plugins/colibri';
import i18n from 'common/services/i18n.service';
import { showNotification } from 'components/Notifications/redux';

import { InnerAPI } from 'common/redux';

export const NAMESPACE = '@cabinet';

const setBannersList = createAction(`${NAMESPACE}/SET_BANNERS_LIST`);
const setOrdersList = createAction(`${NAMESPACE}/SET_ORDERS_LIST`);
const setTariffsList = createAction(`${NAMESPACE}/SET_TARIFFS_LIST`);
const setUserData = createAction(`${NAMESPACE}/SET_USER_DATA`);
const setPricesData = createAction(`${NAMESPACE}/SET_PRICES_DATA`);
const setBalanceData = createAction(`${NAMESPACE}/SET_BALANCE_DATA`);

class CabinetAPI extends InnerAPI {
  async getGlobalData() {
    const [objects, user] = await Promise.all([
      this.getUserObjects(),
      this.getUserData(),
      this.getTariffsList(),
    ]);

    this.store.dispatch(setUserData({
      name: user.fio,
      balance: user.balance,
      roles: user.roles,
      objects,
    }));
  }

  @Endpoint({
    path: '/banners',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getBannersList(response) {
    if (!response.ok) {
      return;
    }

    this.store.dispatch(setBannersList(response.body.banners));
  }

  @Endpoint({
    path: '/client/info'
  })
  async getUserData(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    return response.body;
  }

  @Endpoint({
    path: '/order/list',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getOrdersList(response) {
    if (!response.ok) {
      return;
    }

    this.store.dispatch(setOrdersList(response.body));
  }

  @Endpoint({
    path: '/tariff/list',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getTariffsList(response) {
    if (!response.ok) {
      return;
    }

    this.store.dispatch(setTariffsList(response.body));
  }

  @Endpoint({
    path: '/chart_price',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getPricesData(response) {
    if (!response.ok) {
      return;
    }


    this.store.dispatch(setPricesData(response.body));
  }

  @Endpoint({
    path: '/tariff/property/list',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getUserObjects(response) {
    if (!response.ok) {
      return [];
    }

    return response.body;
  }

  @Endpoint({
    path: '/property/balance',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getBalance(response) {
    if (!response.ok) {
      return [];
    }

    this.store.dispatch(setBalanceData(response.body));
  }

  @Endpoint({
    path: '/tariff/property/add',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async setTariffToObject(response) {
    if (!response.ok) {
      return;
    }

    await this.getGlobalData();

    return response;
  }

  @Endpoint({
    path: '/installment/pay',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async installmentPay(response) {
    if (!response.ok) {
      this.store.dispatch(showNotification({
        type: 'error',
        title: i18n.t('Технічна помилка'),
        message: i18n.t('Виникла технічна помилка, повторіть запит пізніше')
      }));
    }

    if (response.ok && !response.body.redirect_url) {
      this.store.dispatch(showNotification({
        type: 'success',
        title: i18n.t('Заявка успішно відправлена'),
        message: i18n.t('Очікуйте повідомлення на своєму телефоні')
      }));
    }

    return response.body;
  }
}

export const Cabinet = new CabinetAPI();

export const cabinetReducer = handleActions({
  [setBannersList]: (state, { payload }) => ({
    ...state,
    banners: payload.map(item => ({
      title: item.name,
      description: item.description,
      image: item.img_url,
      link: item.link_url,
    })),
  }),
  [setOrdersList]: (state, { payload }) => ({
    ...state,
    orders: {
      page: payload.paginator.page,
      pages: payload.paginator.pages,
      total: payload.paginator.total_items || 0,
      limit: payload.paginator.limit,
      items: payload.orders.map(item => ({
        month: i18n.t(item.month),
        created: moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        volume: item.volume,
        price: item.price,
        amount: item.amount,
        status: item.payed ? 'PAID' : 'NO_PAID',
        payLink: item.liqpay_url,
        invoiceLink: item.pdf_url,
      })),
    },
  }),
  [setTariffsList]: (state, { payload }) => ({
    ...state,
    tariffs: payload.map(item => ({
      id: item.id,
      icon: item.img,
      name: item.name,
      description: item.description,
      tariff: item.price,
      fixed: Boolean(item.fixed),
      props: item.properties,
      expiration: item.expiration_date,
    })),
  }),
  [setUserData]: (state, { payload }) => ({
    ...state,
    user: {
      ...payload,
      showTariffs: payload.objects.some(item => !item.tariff_id),
      objects: payload.objects.map(item => ({
        id: item.id,
        address: item.address,
        account: item.account_number,
        tariff: item.tariff ? {
          id: item.tariff.id,
          name: item.tariff.name,
          description: item.tariff.description,
          tariff: item.tariff.price,
          expiration: item.tariff.expiration_date,
        } : null,
        tariffId: item.tariff_id,
        price: item.price,
        active: Boolean(item.tariff_id),
      })),
    }
  }),
  [setPricesData]: (state, { payload }) => ({
    ...state,
    prices: {
      date: moment().format('DD.MM.YYYY'),
      data: payload.map(item => item.price.toFixed(2)),
      labels: payload.map(item => item.month),
    }
  }),
  [setBalanceData]: (state, { payload }) => ({
    ...state,
    balance: payload,
  }),
}, {
  user: {
    name: 'Романов Віталій Вікторович',
    balance: 100500,
    roles: [],
    objects: [],
  },
  banners: [],
  balance: [],
  orders: {
    page: 1,
    pages: 1,
    limit: 10,
    total: 0,
    items: [],
  },
  tariffs: [],
  prices: {
    date: null,
    data: [],
    labels: [],
  }
})
