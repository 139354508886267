import { Handler } from './handler';

class LocationProps extends Handler {
	set(params) {
		Object.entries(params).forEach(([key, val]) => {
			this[key] = val;
		});

		this.trigger({ type: 'set', target: this });
	}

	add(key, value) {
		this[key] = value;
		this.trigger({ type: 'add', target: this });
	}

	delete(key) {
		delete this[key];
		this.trigger({ type: 'delete', target: this });
	}
}

export class Location {
	url = '';
	href = '';
	path = '';
	search = '';
	query = Object.create(LocationProps.prototype);
	params = Object.create(LocationProps.prototype);

	constructor(url, path, { query = {}, params = {} } = {}) {
		this.path = path;
		this.url = url;

		this.setParams(params);
		this.setQuery(query);

		this.params.onChange(({ target }) => {
			this.setParams(target);
		});

		this.query.onChange(({ target }) => {
			this.setQuery(target);
		});
	}

	setParams(params) {
		this.href = `${this.url}${this.path}`.replace(/:(\w+)/g, (match, key) => {
			this.params[key] = params[key] || key;
			return this.params[key];
		});

		return this;
	}

	setQuery(query) {
		this.search = Object.entries(query)
			.reduce((target, [key, value]) => {
				if (value === undefined) {
					return target;
				}

				target.push(`${key}=${encodeURIComponent(value)}`);

				this.query[key] = value;

				return target;
			}, [])
			.join('&');

		if (this.search) {
			this.href = `${this.url}${this.path}?${this.search}`;
		}

		return this;
	}

	[Symbol.toPrimitive]() {
		return this.href;
	}

	[Symbol.toStringTag]() {
		return 'Location';
	}

	toString() {
		return this.href;
	}
}
