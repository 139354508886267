import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import i18n from 'common/services/i18n.service';
import { numberFormatter } from 'common/helpers/string.helper';
import { withLoader } from 'components/Loader/redux';

import InvoiceControls from 'common/components/InvoiceControls';

import Table from 'components/Table';
import Status from 'components/Status';
import Amount from 'components/Amount';
import Value from 'components/Value';
import Loader from 'components/Loader';

import { Cabinet } from 'common/redux/cabinet';
import { selectOrdersList } from 'common/redux/cabinet/selectors';

import { fetch } from 'common/services/fetch.service';

import './index.scss';

const STATUSES_MAP = {
  PAID: {
    color: 'green',
    get label() {
      return i18n.t('Сплачено');
    }
  },
  NO_PAID: {
    color: 'red',
    get label() {
      return i18n.t('Не сплачено');
    }
  },
}

@fetch({
  orders: [selectOrdersList, () => Cabinet.getOrdersList({ query: { limit: 1 } })]
}, null, {
  loader: 'invoices',
})
@withTranslation()
@BEM('invoices-block')
export class InvoicesBlock extends React.PureComponent {
  static displayName = 'InvoicesBlock';

  onPageChange(page) {
    withLoader('invoices', Cabinet.getOrdersList, Cabinet)({
      query: {
        limit: this.props.orders.limit,
        page,
      }
    });
  }

  render() {
    const { orders, loading, t, bem } = this.props;

    return (
      <section className={bem()}>
        <article className={bem('main')}>
          <Table
            theme="blue"
            hover
            columns={[
              {
                id: 'month',
                width: 120,
                label: t('Місяць'),
              }, {
                id: 'price',
                label: t('Ціна за 1м.куб.'),
              }, {
                id: 'volume',
                label: t('Фактичний об’єм споживання'),
              }, {
                id: 'amount',
                label: t('Сума до сплати'),
              }, {
                id: 'status',
                label: t('Статус'),
              }, {
                id: 'controls',
                width: 140,
                label: t('Завантажити рахунок'),
              }
            ]}
            rows={orders.items.map(item => ({
              ...item,
              volume: `${numberFormatter(item.volume)} м³`,
              price: (
                <div className={bem('price')}>
                  <b><Amount>{item.price}</Amount></b> {t('з ПДВ')}
                </div>
              ),
              amount: (
                <Value color="blue">
                  <Amount>{item.amount}</Amount>
                </Value>
              ),
              status: (
                <Status color={STATUSES_MAP[item.status].color}>
                  {STATUSES_MAP[item.status].label}
                </Status>
              ),
              controls: (
                <InvoiceControls
                  pay={item.status === 'NO_PAID'}
                  onDownload={() => window.open(item.invoiceLink, '_blank')}
                  onPay={() => window.open(item.payLink, '_blank')}
                />
              )
            }))}
            placeholder={(
              <div className={bem('placeholder')}>
                {!loading && t('У вас ще немає виставлених рахунків')}
              </div>
            )}
            pagination={{
              page: orders.page,
              pages: orders.pages,
              limit: orders.limit,
              total: orders.total,
              onChange: page => this.onPageChange(page)
            }}
          />
        </article>
        <Loader name="invoices" color="white" size={48} />
      </section>
    );
  }
}
