import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { Line } from 'react-chartjs-2';

import Loader from 'components/Loader';

import { Cabinet } from 'common/redux/cabinet';
import { selectPrices } from 'common/redux/cabinet/selectors';

import { numberFormatter } from 'common/helpers/string.helper';
import { fetch } from 'common/services/fetch.service';
import { MONTHS } from 'common/helpers/months.helper';

import './index.scss';

@fetch({
  prices: [selectPrices, () => Cabinet.getPricesData()],
}, null, {
  loader: 'prices',
})
@withTranslation()
@BEM('price-chart-block')
export class PriceChartBlock extends React.PureComponent {
  static displayName = 'PriceChartBlock';

  $canvas = React.createRef();
  backgroundFill = undefined;

  componentDidMount() {
    const context = this.$canvas.current.getContext('2d');
    const gradientFill = context.createLinearGradient(0, 0, 0, 300);

    gradientFill.addColorStop(0, 'rgb(8, 82, 249, 0.3)');
    gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.0)');

    this.backgroundFill = gradientFill;
  }

  render() {
    const { prices, t, bem } = this.props;

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <div className={bem('label')}>
            Ціна, грн.
          </div>
          <div className={bem('date')}>
            {prices.date}
          </div>
        </header>
        <article className={bem('main')}>
          <Line
            height={100}
            options={{
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltips: {
                displayColors: false,
                callbacks: {
                  label: (item) => `${t('Ціна')} ${numberFormatter(item.value)} грн.`,
                }
              },
              layout: {
                padding: {
                  left: -4,
                }
              },
              scales: {
                xAxes: [{
                  ticks: {
                    fontColor: '#819099',
                  }
                }],
                yAxes: [{
                  ticks: {
                    padding: 4,
                    fontColor: '#819099',
                    callback: value => numberFormatter(value),
                  }
                }]
              }
            }}
            data={{
              labels: prices.labels.map(item => MONTHS[item]),
              datasets: [
                {
                  fill: true,
                  borderColor: '#0852f9',
                  pointBorderColor: '#109cf1',
                  pointBackgroundColor: '#fff',
                  backgroundColor: this.backgroundFill,
                  data: prices.data,
                }
              ]
            }}
          />
        </article>
        <Loader name="prices" color="white" />
        <canvas hidden ref={this.$canvas} />
      </section>
    );
  }
}
