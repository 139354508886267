import { PluginsRegistry, PLUGIN_TYPES } from './plugins';
import { HeadersMap } from './headers';
import { HttpMethod } from './methods';

export class API {
	static get plugins() {
		return PluginsRegistry.create(this);
	}

	static verify({ status }) {
	  return Promise[(status >= 200 && status < 300) ? 'resolve' : 'reject'](status);
  }

  async applyHook(type, data) {
    const hooks = this.constructor.plugins.get(PLUGIN_TYPES.HOOKS).filter(hook => hook[type]);

    for (const hook of hooks) {
      data = await hook[type](data);
    }

    return data;
  }

	async fetch(request, mixins) {
		const fetchOptions = {
			method: request.method,
			body: undefined,
      headers: request.headers,
		};

		if (HttpMethod.hasRequestBody(request.method) && request.body) {
			const formatter = this.constructor.plugins.find(PLUGIN_TYPES.BODY_FORMATTER);

			if (formatter && formatter.match({ request })) {
				fetchOptions.body = formatter.use({ request });
			}
		}

    request = await this.before({ request });

		const result = await fetch(request.location.href, fetchOptions).catch((error) => {
      return this.catch({ ...mixins, request, response, error });
    });

		const response = {
			status: result.status,
			headers: HeadersMap.create([...result.headers.entries()]),
      ok: result.ok,
			body: null,
		};

		if (HttpMethod.hasResponseBody(request.method, response.status)) {
			const parser = this.constructor.plugins.find(PLUGIN_TYPES.BODY_PARSER);

			if (parser && parser.match({ request, response: result })) {
				response.body = await parser.use({ request, response: result });
			}
		}

    return this.constructor.verify(response).then(() => {
      return response;
    }).catch(() => {
      response.error = true;

      return this.catch({ ...mixins, request, response });
    });
	}

	before({ request }) {
	  return Promise.resolve(request);
  }

	catch({ response }) {
	  return Promise.reject(response);
  }
}
