import { API, PLUGIN_TYPES } from './index';

export const colibriMiddleware = (store) => {
  API.plugins.register(PLUGIN_TYPES.MIXIN, {
    use({ context }) {
      context.store = store;
      return { store };
    }
  });

  return next => action => next(action);
};
