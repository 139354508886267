import i18n from 'common/services/i18n.service';

export const MONTHS = {
  get 1() {
    return i18n.t('Січень');
  },
  get 2() {
    return i18n.t('Лютий');
  },
  get 3() {
    return i18n.t('Березень');
  },
  get 4() {
    return i18n.t('Квітень');
  },
  get 5() {
    return i18n.t('Травень');
  },
  get 6() {
    return i18n.t('Червень');
  },
  get 7() {
    return i18n.t('Липень');
  },
  get 8() {
    return i18n.t('Серпень');
  },
  get 9() {
    return i18n.t('Вересень');
  },
  get 10() {
    return i18n.t('Жовтень');
  },
  get 11() {
    return i18n.t('Листопад');
  },
  get 12() {
    return i18n.t('Грудень');
  },
}
