import { createAction, handleActions } from 'redux-actions';

import { Endpoint, HttpMethod } from 'common/plugins/colibri';

import { InnerAPI } from 'common/redux';

export const NAMESPACE = '@banners';

const setBannersList = createAction(`${NAMESPACE}/SET_BANNERS`);

class BannersAPI extends InnerAPI {
  @Endpoint({
    path: '/banners',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getBannersList(response) {
    if (!response.ok) {
      return;
    }

    this.store.dispatch(setBannersList(response.body.banners));
  }
}

export const Banners = new BannersAPI();

export const bannersReducer = handleActions({
  [setBannersList]: (state, { payload }) => ({
    ...state,
    list: payload.map(item => ({
      title: item.name,
      description: item.description,
      image: item.img_url,
      link: item.link_url,
    })),
  })
}, {
  list: [],
})
