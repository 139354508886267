import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';

import Query from '/common/helpers/query.helper';

import routes from 'common/routes';

import Notifications from 'components/Notifications';
import { hideNotification } from 'components/Notifications/redux';

import { MainLayout } from 'common/layouts/Main';
import { CatchProvider } from 'common/blocks/CatchProvider';

import i18n from 'common/services/i18n.service';

export const App = () => {
  const location = useLocation();
  const notifications = useSelector(state => state.notifications);
  const dispatch = useDispatch();

  return (
    <CatchProvider>
      <I18nextProvider i18n={i18n}>
        <MainLayout>
          {renderRoutes(routes, {}, {
            location: {
              ...location,
              query: Query.parse(location.search)
            }
          })}
        </MainLayout>
        <Notifications
          notifications={notifications}
          onHide={(notification) => dispatch(hideNotification(notification))}
        />
      </I18nextProvider>
    </CatchProvider>
  );
};
