import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import Button from 'components/Button';

import { stringToHslColor, compressName } from 'common/helpers/string.helper';

import './index.scss';

@withTranslation()
@BEM('cabinet-header-block')
export class CabinetHeader extends React.PureComponent {
  static displayName = 'CabinetHeader';

  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    onExit: PropTypes.func,
  };

  static defaultProps = {
    user: {
      name: '',
    },
    onExit: () => {},
  };

  render() {
    const { user, onExit, t, bem } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('company')} />
        <div className={bem('user')}>
          <div className={bem('name')}>
            <div className={bem('user-name')}>
              {user.name}
            </div>

            <div className={bem('exit')}>
              <Button theme="link" lower block onClick={onExit} to="/login">
                {t('Вихід')}
                <span className={bem('icon')}>
                  <Icon name="exit_to_app" />
                </span>
              </Button>
            </div>
          </div>
          <div
            className={bem('logo')}
            style={{
              borderColor: stringToHslColor(user.name, 30, 65),
              backgroundColor: stringToHslColor(user.name, 30, 70),
            }}
          >
            {compressName(user.name)}
          </div>
        </div>
      </section>
    );
  }
}
