export default {
  parse(search = '', arraySplitter) {
    if (search.length === 0) {
      return undefined;
    }

    return search.substr(1).split('&').reduce((target, item) => {
      const [key, value] = item.split('=');

      target[key] = decodeURIComponent(value);

      if (arraySplitter && target[key].includes(arraySplitter)) {
        target[key] = target[key].split(arraySplitter);
      }

      return target;
    }, { });
  },

  serialize(params) {
    return Object.entries(params).reduce((target, [key, value]) => {
      if (value === undefined) {
        return target;
      }

      return ([...target, `${key}=${encodeURIComponent(value)}`]);
    }, []).join('&')
  }
};
