import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';

import CONFIG from 'common/config';
import { configureStore } from 'common/store';
import { createHistory } from 'common/services/history.service';

import { App } from 'common/main';

const getLocalState = () => {
  try {
    return JSON.parse(decodeURIComponent(window.__REDUX_STATE__));
  } catch (e) {
    return { };
  }
};

const $app = document.getElementById('app');
const history = createHistory('browser');
const store = configureStore({ history }, getLocalState());

Sentry.init({ dsn: CONFIG.SENTRY_DSN, release: CONFIG.RELEASE_VERSION });

const View = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render((<View />), $app);

if (module.hot) {
  module.hot.accept();
}
