import React from 'react';
import { Helmet } from 'react-helmet-async';

import Loader from 'components/Loader';

import './index.scss';

export const MainLayout = ({ children }) => (
  <React.Fragment>
    <Helmet defaultTitle="TAS Energy">
      <meta name="description" content="TAS Energy" />
    </Helmet>
    {children}
    <Loader name="global" fixed color="white" />
  </React.Fragment>
);
