import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { H4 } from 'components/Title';
import Loader from 'components/Loader';

import TariffsList from 'common/components/TariffsList';

import { withLoader } from 'components/Loader/redux';
import { Cabinet } from 'common/redux/cabinet';

import './index.scss';

@withTranslation()
@BEM('tariffs-block')
export class TariffsBlock extends React.PureComponent {
  static displayName = 'TariffsBlock';

  static propTypes = {
    user: PropTypes.string,
    tariffs: PropTypes.arrayOf(PropTypes.any),
    objects: PropTypes.arrayOf(PropTypes.any),
  }

  static defaultProps = {
    tariffs: [],
    objects: [],
  }

  state = {
    open: null,
  };

  async onSelect(tariff, object) {
    await withLoader('tariff', Cabinet.setTariffToObject, Cabinet)({
      body: {
        tariff_id: tariff.id,
        property_id: object.id,
      }
    });

    this.setState({ open: null });
  }

  render() {
    const { user, tariffs, objects, t, bem } = this.props;
    const { open } = this.state;

    return (
      <section className={bem()}>
        <header className={bem('header')}>
          <H4>{t('Шановний(на) {{user}}!', { user })}</H4>
        </header>
        <article className={bem('main')}>
          <ul className={bem('objects')}>
            {objects.map((item, index) => (
              <li key={item.address} className={bem('object-item')}>
                {item.active && (
                  <div className={bem('box')}>
                    {t('По об\'єкту №{{number}} - {{address}} встановлено тариф "{{tariff}}" - ', {
                      number: index + 1,
                      address: item.address,
                      tariff: item.tariff.name,
                      price: item.tariff.tariff,
                    })}
                    <b>{t('{{price}} грн з ПДВ', { price: item.price })}</b>
                    {t(' за 1м.куб.')} &nbsp;
                    {t('Тариф діє до ')} &nbsp;
                    <b>{item.tariff.expiration}</b>

                    {item.account && (
                      <div className={bem('account')}>
                        {t('Особовий рахунок для об\'єкта №{{number}} - ', { number: index + 1 })}
                        <b>{item.account}</b>
                      </div>
                    )}
                  </div>
                )}
                {!item.active && (
                  <div className={bem('box')}>
                    <div className={bem('choose')}>
                      <div>
                        {t('Будь ласка, оберіть тариф для об\'єкта №{{number}} - {{address}}', {
                          number: index + 1,
                          address: item.address,
                        })}
                        {item.account && (
                          <div className={bem('account')}>
                            {t('Особовий рахунок для об\'єкта №{{number}} - ', { number: index + 1 })}
                            <b>{item.account}</b>
                          </div>
                        )}
                      </div>
                      <button
                        className={bem('choose-button')}
                        onClick={() => this.setState({ open: item.id })}
                      >
                        {t('Обрати')}
                      </button>
                    </div>

                    <div className={bem('tariffs', { open: open === item.id })}>
                      <TariffsList
                        tariffs={tariffs}
                        onSelect={tariff => this.onSelect(tariff, item)}
                      />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </article>
        <Loader color="white" name="tariff" />
      </section>
    );
  }
}
