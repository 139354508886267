import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@withTranslation()
@BEM('tariff-component')
export default class Tariff extends React.PureComponent {
  static displayName = 'TariffComponent';

  static propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    props: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })),
    fixed: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    fixed: false,
    onClick: () => {},
  };

  render() {
    const { name, icon, description, props, fixed, onClick, t, bem } = this.props;

    return (
      <section className={bem()}>
        <article className={bem('main')}>
          <div className={bem('icon')}>
            {icon}
          </div>
          <div className={bem('name')}>
            «{name}»
          </div>
          <div className={bem('description')}>
            {description}
          </div>
          <ul className={bem('props')}>
            {props.map((item, index) => (
              <li key={index} className={bem('props-item')}>
                <div className={bem('prop-name')}>
                  {item.name}
                </div>
                <div className={bem('prop-description')}>
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
          {fixed && (
            <div className={bem('fixed')}>
              <span className={bem('fixed-marker')}>*</span>
              {t('ціна на газ є фіксованою та не зміниться в разі зростання ціни')}
            </div>
          )}
        </article>
        <footer className={bem('footer')}>
          <button className={bem('button')} onClick={onClick}>
            {t('Замовити')}
          </button>
        </footer>
      </section>
    );
  }
}
