import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { H2 } from 'components/Title';

import './index.scss';

@withTranslation()
@BEM('payment-success-page')
export class PaymentSuccessPage extends React.PureComponent {
  static displayName = 'PaymentSuccessPage';

  render() {
    const { bem, t } = this.props;

    return (
      <section className={bem()}>
        <Helmet defaultTitle="Дякуємо за оплату - TAS Energy">
          <meta name="description" content="Дякуємо за оплату - TAS Energy" />
        </Helmet>
        <header className={bem('header')}>
          <Link to="/">
            <img className={bem('logo')} src="/assets/images/logo.svg" />
          </Link>
        </header>
        <article className={bem('main')}>
          <H2>{t('Дякуємо за оплату')}</H2>
          <img className={bem('image')} src="/assets/images/payment-success.svg" />
        </article>
      </section>
    );
  }
}
