import { createSelector } from 'reselect';

import { NAMESPACE } from 'common/redux/cabinet';

export const cabinet = (state) => state[NAMESPACE];
export const lang = (state) => state.i18n.lang;

export const selectBannersList = createSelector(
  cabinet,
  lang,
  ({ banners }, lang) => banners.map(item => ({
    ...item,
    title: item.title[lang],
    description: item.description[lang],
  })),
);

export const selectOrdersList = createSelector(
  cabinet,
  ({ orders }) => orders,
);

export const selectTariffsList = createSelector(
  cabinet,
  lang,
  ({ tariffs }, lang) => tariffs.map(item => ({
    ...item,
    name: item.name[lang],
    description: item.description[lang],
    props: item.props.map(prop => ({
      name: prop.name[lang],
      description: prop.value[lang],
    })),
  })),
);

export const selectTariffById = tariffId => createSelector(
  selectTariffsList,
  tariffs => tariffs.find(({ id }) => id === tariffId),
)

export const selectUserObjects = createSelector(
  cabinet,
  ({ user }) => user.objects,
);

export const selectUserData = createSelector(
  cabinet,
  lang,
  ({ user }, lang) => ({
    ...user,
    objects: user.objects.map(item => ({
      ...item,
      tariff: item.tariff ? {
        ...item.tariff,
        name: item.tariff.name[lang],
      } : null,
    }))
  }),
);

export const selectPrices = createSelector(
  cabinet,
  ({ prices }) => prices,
)

export const selectBalance = createSelector(
  cabinet,
  ({ balance }) => balance,
)
